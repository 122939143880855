import { useState } from "react";
import dayjs from "dayjs";
import { Amount, StandardSearchGroup, StandardTable } from "standard";
import { reportCommission, reportCommissionSummary } from "../../utils/request";
import { dayStart } from "../../utils/formatTime";

export default function CommissionPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
  });
  const [version, setVersion] = useState(0);
  const [summary, setSummary] = useState(null);

  const query = async (params) => {
    querySummary(params);
    const result = await reportCommission(params);
    return result;
  }

  const querySummary = async (params) => {
    const result = await reportCommissionSummary(params);
    setSummary([
      { label: '汇总', value: result },
    ]);
  }

  const modeName = (value) => {
    switch (value) {
      case 'BANK_CARD': return '银行卡转账';
      case 'R_DPAY_U': return '发R存U';
      case 'ETHEREUM': return '以太坊';
      case 'HELP_BUY': return '代付';
      case 'MCH_WITHDRAW': return '商户提款';
      default:
        return value;
    }
  }


  return (
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '日期', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }} />
      <StandardTable
        columns={[
          { headerName: '日期', field: 'date', },
          { headerName: '下级', field: 'inferiorName', },
          {
            headerName: '类型', field: 'inferiorType',
            renderCell: (params) => params.value === 'AGENT' ? '代理' : '商户'
          },
          {
            headerName: '佣金', field: 'sum',
            renderCell: (params) => <Amount value={params.value} />
          },
          { headerName: '货币', field: 'currency', },
          {
            headerName: '模式', field: 'mode',
            renderCell: (params) => modeName(params.value)
          },
        ]}
        query={query}
        queryParams={queryParams}
        summary={summary}
        version={version}
        notFoundSrc={`/no_items_found.png`}
      />
    </>
  );
}
