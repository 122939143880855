import { useEffect } from 'react';

export default function Heartbeat({ callback, interval = 10000 }) {
  useEffect(() => {
    // 启动心跳定时器
    const heartbeatInterval = setInterval(callback, interval);

    // 在组件卸载时清除定时器
    return () => {
      clearInterval(heartbeatInterval);
    };
  }, []);

  return <div />;
}
