import { useState } from "react";
import dayjs from "dayjs";
import { StandardSearchGroup, StandardTable } from "standard";
import { logList } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";

export default function LogPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
  });
  const [version, setVersion] = useState(0);

  return (
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart), } },
          { type: 'date', name: 'endStart', label: '结束时间', params: { value: dayjs(queryParams.timeEnd) } },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }} />
      <StandardTable
        columns={[
          { headerName: '创建时间', field: 'createTime' },
          { headerName: '内容', field: 'name' },
          { headerName: 'IP', field: 'remoteAddr' },
          { headerName: '参数', field: 'params' },
        ]}
        query={(params) => logList(params)}
        queryParams={queryParams}
        version={version}
        notFoundSrc={`/no_items_found.png`}
      />
    </>
  );
}