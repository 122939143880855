import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, Stack, Pagination as MUIPagination, IconButton, Input
} from "@mui/material";
import { LastPage, FirstPage, KeyboardDoubleArrowRight } from '@mui/icons-material';

const summaryItem = ({ label, value }) => (
  <Box key={label} component="span" direction='row'>
    <Typography component="span" color='black'> {label} : </Typography>
    <Typography component="span"> {value} </Typography>
  </Box>
);

function Button({ onClick, icon }) {
  return (
    <IconButton
      sx={{
        paddingX: 0.5,
        paddingY: 0.5,
      }}
      size="small"
      onClick={onClick}
    >
      {icon}
    </IconButton>
  );
}

function Description({ rowCount, pageSize }) {
  return (
    <Stack
      alignItems='center'
      direction='row'
      paddingLeft={2}
    >
      <Box>
        <Typography paddingRight={1} fontSize={15} variant="caption">
          共<Typography paddingX={0.5} color='red' fontSize={15} variant="caption">
            {rowCount}
          </Typography>
          条,
        </Typography>
        <Typography fontSize={15} variant="caption">
          每页 {pageSize} 条
        </Typography>
      </Box>
    </Stack>
  );
}

function InputPage({ init, jumpTo }) {
  const [page, setPage] = useState(init);
  return (
    <Box sx={{ paddingLeft: 1 }}>
      <Input
        disableUnderline={true}
        endAdornment={<div />}
        inputProps={{
          padding: 0
        }}
        sx={{
          fontSize: 13,
          border: 1,
          borderRadius: 1,
          borderColor: 'grey',
          width: 30,
          height: 20,
          textAlign: 'center'
        }}
        value={page}
        onChange={(event) => setPage(parseInt(event.target.value))}
      />
      <Button
        onClick={() => {
          jumpTo(page);
        }}
        icon={<KeyboardDoubleArrowRight fontSize="inherit" />}
      />
    </Box >
  );
}

function Pagination({ rowCount, pagination, setPagination }) {
  const jumpTo = (index) => setPagination({ ...pagination, page: index });
  const last = Math.ceil(rowCount / pagination.pageSize);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingRight={1}
    >
      <Button
        onClick={() => jumpTo(1)}
        icon={<FirstPage fontSize="inherit" />}
      />
      <MUIPagination
        size='small'
        // pageSizeOptions={[15, 30, 60]}
        page={pagination.page}
        count={last}
        shape="rounded"
        onChange={(_, page) => {
          jumpTo(page)
        }}
      />
      <Button
        onClick={() => jumpTo(last)}
        icon={<LastPage fontSize="inherit" />}
      />
      <InputPage init={pagination.page} jumpTo={jumpTo} />
    </Stack>
  );
}

export default function Footer({ rowCount, pagination, setPagination, summary, pageDisable = false }) {
  return (
    <Stack sx={{
      borderTop: `1px solid rgba(128, 128, 128, 0.1)`,
      alignItems: 'center',
    }}>
      {summary && <Box sx={{
        width: '100%',
        padding: 0.5,
        display: "flex",
        direction: 'row',
        justifyContent: "space-around",
      }}>
        {summary.map(v => summaryItem(v))}
      </Box>}


      {!pageDisable &&
        <Box sx={{
          width: '100%',
          display: "flex",
          direction: 'row',
          justifyContent: "space-between",
          borderTop: `1px solid rgba(128, 128, 128, 0.1)`,
          borderColor: "default",
          paddingY: 0.5,
        }}
        >
          <Description rowCount={rowCount} pageSize={pagination.pageSize} />
          <Pagination rowCount={rowCount} pagination={pagination} setPagination={setPagination} />
        </Box>}
    </Stack>
  );
}

Footer.propTypes = {
  rowCount: PropTypes.number,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  summary: PropTypes.array,
};
