import PropTypes from 'prop-types';
import {
  Box, Typography, Stack
} from "@mui/material";

export function Amount({ value, sub = false, paddingRight = 30 }) {
  const number = value.toFixed(2);
  let typography = <Typography color='green'>{number}</Typography>;
  if (number === 0) {
    typography = <Typography>{number}</Typography>
  } else if (sub || number < 0) {
    typography = <Typography color='red'>- {number}</Typography>
  }
  return (
    <Stack direction='row' width={110} justifyContent='right'>
      {typography}
      <Box width={paddingRight} />
    </Stack>
  );
}
Amount.propTypes = {
  value: PropTypes.number,
  sub: PropTypes.bool,
};
