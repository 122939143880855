import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useForm, Controller } from "react-hook-form";
import {
  Card, Button, Stack, TextField, Divider, Box,
  FormControl, InputLabel, Select, MenuItem, Autocomplete, Toolbar, IconButton,
  Typography,
} from "@mui/material";
import { Close } from '@mui/icons-material';
import useResponsive from "../hooks/useResponsive";

export function WithdrawForm({ bankCodes, queryBalance, onClose, onSubmit }) {
  const isDesktop = useResponsive('up', 'lg');
  const { register, handleSubmit, control } = useForm();
  const [bank, setBank] = useState();
  const [available, setAvailable] = useState('');
  const [currency, setCurrency] = useState('CNY');
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = (data) => {
    setIsLoading(true);
    async function func() {
      await onSubmit({ ...data, bankCode: bank && bank.code, bankName: bank && bank.label });
    }
    func();
  }

  useEffect(() => {
    async function func() {
      const availableBalance = await queryBalance(currency);
      setAvailable(availableBalance);
    }
    func();
  }, [currency]);

  const AddField = ({ name, label, type = 'text', onChange }) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required: '必填字段' }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            size="small"
            label={label}
            type={type}
            inputProps={{
              step: "0.01"
            }}
            onBlur={(event) => {
              // 移除输入字段中的空格
              const valueWithoutSpaces = removeSpaces(event.target.value);
              if (onChange) {
                field.onChange(onChange(valueWithoutSpaces));
              } else {
                field.onChange(valueWithoutSpaces);
              }
            }}
          />
        )}
      />
    );
  };
  AddField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
  };

  const keepLettersAndNumbers = (inputString) => {
    // 使用正则表达式匹配字母（包括大小写）和数字，邮箱
    return inputString.replace(/[^a-zA-Z0-9\-_.@]/g, '');
  }

  const removeSpaces = (value) => {
    // 移除字符串中的空格
    return value.replace(/\s/g, '');
  };

  const findBankCode = (value) => {
    value = removeSpaces(value);
    // 使用for...in循环遍历对象中的所有键
    for (let key in bankCodes) {
      if (bankCodes.hasOwnProperty(key)) { // 确保key是对象自身的属性，而非继承的属性
        if (bankCodes[key] === value) {  // 比较当前键的值是否等于搜索的值
          return key;  // 返回匹配值的键
        }
      }
    }
    return 'ANON';
  }

  // 检测当前屏幕宽度
  return (
    <Card align='center' sx={{ minWidth: isDesktop ? 500 : '100%' }}>
      <Toolbar>
        <Box width={50}>
          <div />
        </Box>
        <Typography variant="h4" style={{ flex: 1 }}>
          提款
        </Typography>
        <Box width={50}>
          <IconButton edge="end" aria-label="关闭" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Toolbar>


      <Divider />
      <form onSubmit={handleSubmit(handleConfirm)}>
        <Stack padding={4} spacing={2}>
          <AddField name='cardId' label='银行卡号' onChange={keepLettersAndNumbers} />
          <AddField name='accountName' label='账户名称' />
          <Autocomplete
            {...register("bankName")}
            freeSolo
            options={Object.entries(bankCodes).map(([k, v]) => ({ label: v, code: k }))}
            renderInput={(params) =>
              <TextField
                {...params}
                size="small"
                label="银行名称"
                onChange={(event) => {
                  // 输入后设置银行
                  const value = removeSpaces(event.target.value);
                  setBank({ code: findBankCode(value), label: value })
                }}
              />}
            onChange={(event, newValue) => {
              // 通过点击选项设置银行
              setBank(newValue)
            }}
          />
          <FormControl variant="filled">
            <InputLabel >货币</InputLabel>
            <Select
              size="small"
              {...register("currency")}
              value={currency}
              align="left"
              required
              onChange={(event) => { setCurrency(event.target.value) }}
            >
              <MenuItem value='CNY'>人民币</MenuItem>
              <MenuItem value='USDT'>USDT</MenuItem>
            </Select>
          </FormControl>
          <TextField size="small" label='可结算余额' value={available} />
          <AddField name='amount' label='结算金额' type="number" />
          <AddField name='googleAuthCode' label='谷歌验证码' type="number" />
        </Stack>
        <Button
          sx={{ bottom: 10 }}
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          提交
        </Button>
      </form>
    </Card>
  );
}
WithdrawForm.propTypes = {
  bankCodes: PropTypes.object,
  queryBalance: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};