import PropTypes from 'prop-types';
import { Grid, styled, Box, Divider, Typography } from "@mui/material";

const Item = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function Pair({ label, value }) {
  return (
    <Grid container item xs={6}>
      <Grid container item xs={4} sx={{ backgroundColor: '#6b686880', border: 1 }}>
        <Item >{label}</Item>
      </Grid>
      <Grid container item xs={8} sx={{ border: 1 }}>
        <Item >{value}</Item>
      </Grid>
    </Grid>);
}

export function Inspector({ title, infos }) {
  // [1, 2, 3, 4, 5, 6, 7] =>  [[1,2], [3,4], [5,6], [7]]
  const infoGroups = infos.reduce((result, value, index) => {
    if (index % 2 === 0) {
      // 创建一个新的子数组并将当前值添加到其中
      result.push([value]);
    } else {
      // 在当前子数组中添加当前值
      result[result.length - 1].push(value);
    }
    return result;
  }, []);
  return (
    <Box>
      <Typography variant="h6" align="center" sx={{ margin: 1 }}>
        {title}
      </Typography>
      <Divider />
      <Box sx={{ border: 1, margin: 3, overflow: 'auto' }}>
        <Grid sx={{ width: 800 }} container>
          {infoGroups.map((v, k) => {
            if (v.length === 2) {
              return (
                <Grid key={k} container item xs={12}>
                  <Pair {...v[0]} />
                  <Pair {...v[1]} />
                </Grid>
              );
            }
            return (
              <Grid key={k} container item xs={12}>
                <Pair {...v[0]} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
Inspector.propTypes = {
  title: PropTypes.string,
  infos: PropTypes.array,
};