import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
} from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TreeItem from '@mui/lab/TreeItem';
import dayjs from "dayjs";
import { StandardSearchGroup, StandardTable, useStore } from "standard";
import { reportMerchant, reportMerchantSummary } from "../../utils/request";

export default function TotalAmountPage() {
  const day = dayjs().startOf('day').format("YYYY-MM-DD");
  const [queryParams, setQueryParams] = useState({
    timeStart: day,
    timeEnd: day,
    inferior: '',
  });
  const [version, setVersion] = useState(0);
  const { store } = useStore();
  const [node, setNode] = useState();
  const [summary, setSummary] = useState(null);


  // 下级关系树状图
  const merchantOptions =
    store && store.tree
      ? store.tree.map(v => {
        return {
          label: v.name,
          value: v.accountId,
          type: v.type,
          superiorId: v.superiorId,
        };
      })
      : [];
  merchantOptions.sort((a, b) => a.label.localeCompare(b.label));

  const querySummary = async (params) => {
    const result = await reportMerchantSummary(params);
    setSummary([
      { label: '总存款', value: result.dsum },
      { label: '总提款', value: result.wsum ?? 0 },
    ]);
  }

  const query = async (params) => {
    if (!node) {
      return null;
    }
    params.inferior = node.value;
    querySummary(params);
    const result = await reportMerchant(params);
    return {
      list: result,
      pagination: {
        total: result.length
      }
    };
  }

  const handleClick = (v) => {
    if (v.type === 'MERCHANT') {
      setNode(v);
    }
  }

  return <>
    <Grid
      container
      direction='row'
      justifyContent="space-around"
    >
      <Grid item xs={3}>
        <Card sx={{ marginRight: 1, height: 'calc(100vh - 65px)', overflow: "auto" }}>
          <Box sx={{ marginTop: 1 }}>
            <InferiorTree
              root={merchantOptions.find(v => v.value === store.accountId)}
              tree={merchantOptions.filter(v => v.value !== store.accountId)}
              onClick={handleClick}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <StandardSearchGroup
          options={[
            { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart), } },
            { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd) } },
            { type: 'text', name: 'inferior', label: '下级商户', params: { value: node ? node.label : '请选择商户', disabled: true } },
          ]}
          setVersion={setVersion}
          setQueryParams={(v) => {
            setQueryParams({ ...queryParams, ...v })
          }} />
        <StandardTable
          columns={[
            { headerName: '日期', field: 'createTime', },
            { headerName: '商户', field: 'name', },
            { headerName: '货币', field: 'currency', },
            { headerName: '存款总额', field: 'dsum', },
            { headerName: '提款总额', field: 'wsum', },
          ]}
          getRowId={(row) => `${row.createTime}${row.name}${row.currency}`}
          query={query}
          queryParams={queryParams}
          version={version}
          summary={summary}
          notFoundSrc={`/no_items_found.png`}
        />
      </Grid>
    </Grid>
  </>
}

function InferiorTree({ root, tree = [], onClick }) {

  const renderTree = (r, nodes) => {
    if (r == null) {
      return <></>;
    }

    const isAgent = r.type === 'AGENT';
    const color = r.type === 'AGENT' ? 'blue' : 'black';
    return (
      <TreeItem
        sx={{ color }}
        key={r.value}
        nodeId={r.value}
        onClick={() => onClick(r)}
        label={
          <Button
            endIcon={isAgent && <SupervisedUserCircleIcon />}
            style={{ textTransform: 'none', textAlign: 'left' }}
            sx={{ '&:hover': { backgroundColor: 'transparent' }, }}
          >
            {r.label}
          </Button>
        }
      >
        {nodes.filter(v => v.superiorId === r.value)
          .map(node => {
            const inferiors = tree.filter(v => v.superiorId === node.value);
            return renderTree(node, inferiors);
          })}
      </TreeItem>
    )
  }

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ flexGrow: 1, maxWidth: 400 }}
    >
      {renderTree(root, tree)}
    </TreeView>
  );
}

