import { useState } from "react";
import dayjs from "dayjs";
import { StandardSearchGroup, StandardTable, Amount } from "standard";
import { statementList } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";

export default function StatementPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
  });
  const [version, setVersion] = useState(0);

  const trxCell = (type) => {
    switch (type) {
      case 'WITHDRAW': return '提款';
      case 'DEPOSIT': return '存款';
      case 'REGULATE': return '调控';
      case 'RECALL': return '撤单';
      default: return '未知';
    }
  }

  return (
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart), } },
          { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd) } },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }} />
      <StandardTable
        columns={[
          { headerName: '创建时间', field: 'createTime', },
          {
            headerName: '金额', field: 'amount',
            renderCell: (params) => <Amount value={params.value} sub={params.row.fundDirection === 'SUB'} />,
          },
          { headerName: '余额', field: 'balance', },
          { headerName: '货币', field: 'currency', },
          {
            headerName: '业务', field: 'trxType',
            renderCell: (params) => trxCell(params.value),
          },
        ]}
        query={(params) => statementList(params)}
        queryParams={queryParams}
        version={version}
        notFoundSrc={`/no_items_found.png`}
      />
    </>
  );
}