import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemButton, Box, List, ListItemText, styled, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 35,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'white',
  borderRadius: theme.shape.borderRadius,
  marginTop: 5,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

function SingleLevel({ item, prefix = '' }) {
  const { title, path, icon } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={prefix + path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: '#1890FF',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}

SingleLevel.propTypes = {
  item: PropTypes.object,
  prefix: PropTypes.string,
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <StyledNavItem onClick={handleClick}>
        <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ backgroundColor: 'black' }} component="div" disablePadding>
          {children.map((child) => (
            <MenuItem key={child.title} item={child} prefix={item.path} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

function MenuItem({ item, ...other }) {
  if (hasChildren(item)) {
    return <MultiLevel item={item} {...other} />;
  } else {
    return <SingleLevel item={item} {...other} />;
  }
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}
NavSection.propTypes = {
  data: PropTypes.array,
};

