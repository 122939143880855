import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { Box, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from './Iconify';

function isEmpty(str) {
  return str === null || str === undefined || str.trim() === ""
}

export default function LoginForm({ submit, requireGoogleAuthCode = false, logo }) {
  const [username, setUsername] = useState();
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [googleAuthCode, setGoogleAuthCode] = useState();
  const [googleAuthCodeError, setGoogleAuthCodeError] = useState(false);
  const hiddenDivRef = useRef(null);

  useEffect(() => {
    // 组件挂载时自动聚焦
    if (hiddenDivRef.current) {
      hiddenDivRef.current.focus();
    }
  }, []);


  const handleSubmit = async () => {
    if (isEmpty(username)) {
      setUsernameError(true);
    }
    if (isEmpty(password)) {
      setPasswordError(true);
    }
    if (isEmpty(password) || isEmpty(username)) {
      return;
    }
    if (requireGoogleAuthCode && isEmpty(googleAuthCode)) {
      setGoogleAuthCodeError(true);
      return;
    }
    submit({
      username,
      password,
      googleAuthCode
    })
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  };

  return (
    <>
      <Stack component='form' spacing={3}>
        {logo}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        <TextField
          onKeyDown={handleKeyDown}
          required
          name="username"
          label="用户名"
          size='small'
          error={Boolean(usernameError)}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
          onClick={() => setUsernameError(false)}
        />
        <TextField
          onKeyDown={handleKeyDown}
          required
          name="password"
          label="密码"
          size='small'
          error={Boolean(passwordError)}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onClick={() => setPasswordError(false)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          onKeyDown={handleKeyDown}
          required
          name="code"
          label="谷歌验证码"
          size='small'
          error={Boolean(googleAuthCodeError)}
          onChange={(event) => {
            setGoogleAuthCode(event.target.value);
          }}
          onClick={() => setGoogleAuthCodeError(false)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton
        fullWidth
        size="medium"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
      >
        登录
      </LoadingButton>
    </>
  );
}

LoginForm.propTypes = {
  requireGoogleAuthCode: PropTypes.bool,
  submit: PropTypes.func,
  logo: PropTypes.element
};