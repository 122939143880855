import { useState } from "react";
import {
  Button,
  Popover,
  Stack,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import {
  Amount, Status, StandardSearchGroup, StandardTable, WithdrawForm, Inspector,
  useBackdrop, useStore, useSnackbar
} from "standard";
import { withdrawList, withdrawExport, withdrawAdd, balance } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";

const orderIdCell = (id, onClick) => {
  return <Button onClick={onClick}>{id}</Button>;
}

export default function WithdrawPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
    orderId: '',
  });
  const [version, setVersion] = useState(0);

  const { store } = useStore();
  const [add, setAdd] = useState(false);
  const [details, setDetails] = useState(null);
  const { setOpen: setBackdrop } = useBackdrop();
  const { setSnackbar } = useSnackbar();

  const handleCloseDetails = () => {
    setBackdrop(false);
    setDetails(null);
  }

  const handleClickDetails = (event, row) => {
    setBackdrop(true);
    setDetails(row)
  }

  const handleClickExport = () => {
    if (queryParams.timeStart === '' || queryParams.timeEnd === '') {
      setSnackbar("请选择时间区间", 'error');
      return;
    }
    withdrawExport(queryParams.timeStart, queryParams.timeEnd);
  }

  const handleWithdrawSubmit = (data) => {
    if (!data.bankCode) {
      setSnackbar('请选择提款银行', 'error');
      return;
    }
    async function add() {
      const result = await withdrawAdd({
        cardId: data.cardId,
        accountName: data.accountName,
        bankName: data.bankName,
        bankCode: data.bankCode,
        amount: data.amount,
        currency: data.currency,
        googleAuthCode: data.googleAuthCode,
      });
      if (result && result.retcode === 0) {
        setSnackbar('提交成功', 'success');
        setVersion(v => v + 1);
      } else {
        setSnackbar(result.retdesc, 'error');
      }
    }
    add();
    setAdd(false);
  }

  const queryBalance = async (currency) => {
    const result = await balance({ currency });
    return result.availableBalance;
  }

  const anchorPosition = { top: window.innerHeight / 6, left: window.innerWidth / 2 };

  const infos = () => {
    return [
      { label: '订单号', value: details.id },
      { label: '金额', value: details.amount },
      { label: '卡号', value: details.cardId },
      { label: '姓名', value: details.accountName },
      { label: '银行', value: details.bankName },
      { label: '状态', value: <Status value={details.status} /> },
      { label: '创建时间', value: details.createTime },
      { label: '打款时间', value: details.completeTime },
    ];
  }
  return <>
    <Popover
      open={Boolean(add)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <WithdrawForm
        bankCodes={store.bankCodes}
        onClose={() => setAdd(false)}
        onSubmit={handleWithdrawSubmit}
        queryBalance={queryBalance}
      />
    </Popover>
    <Popover
      open={Boolean(details != null)}
      onClose={handleCloseDetails}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {details && <Inspector title={'订单详情'} infos={infos()} />}
    </Popover>
    <StandardSearchGroup
      options={[
        { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
        { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day'), } },
      ]}
      setVersion={setVersion}
      setQueryParams={(v) => {
        setQueryParams({ ...queryParams, ...v })
      }} >
      <Stack direction='row' spacing={1} minWidth={250} justifyContent='right'>
        <Button
          startIcon={<DownloadIcon />}
          onClick={handleClickExport}
          color="error"
          variant="outlined"
          style={{ maxHeight: '34px' }}
        >
          导 出
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setAdd(true)}
          color="error"
          variant="contained"
          style={{ maxHeight: '34px' }}
        >
          提 款
        </Button>
      </Stack>
    </StandardSearchGroup>
    <StandardTable
      columns={[
        {
          headerName: '状态', field: 'status',
          renderCell: (params) => <Status value={params.value} />,
        },
        {
          headerName: '订单号', field: 'id',
          renderCell: (params) => orderIdCell(
            params.value,
            (event) => handleClickDetails(event, params.row),
          ),
        },
        { headerName: '货币', field: 'currency', },
        {
          headerName: '金额', field: 'amount', type: 'number',
          renderCell: (params) => <Amount value={params.value} sub />,
        },
        { headerName: '创建时间', field: 'createTime', },
      ]}
      query={withdrawList}
      queryParams={queryParams}
      version={version}
      notFoundSrc={`/no_items_found.png`}
    />
  </>
}