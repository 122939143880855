import { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, Divider, Grid, TextField, Typography } from "@mui/material";

function InputElement({ label, error, onClick, onChange }) {
  return (
    <Grid
      alignItems="center"
      container
    >
      <Grid container item xs={4} direction="row">
        <Grid>
          <Typography color='red' sx={{ mt: 1.5, mr: 1 }}>
            *
          </Typography>
        </Grid>
        <Grid>
          <Typography sx={{ mt: 1, mb: 1 }}>
            {label} :
          </Typography>
        </Grid>
      </Grid>
      <Grid >
        <TextField
          size='small'
          autoFocus
          fullWidth
          margin="dense"
          type='password'
          error={Boolean(error)}
          label={error ?? ''}
          onClick={onClick}
          onChange={(event) => onChange(event.target.value)}
        />
      </Grid>
    </Grid>
  )
}

export default function ResetPassword({ submit, open, onClose }) {
  const [oldPwd, setOldPwd] = useState();
  const [oldPwdErr, setOldPwdErr] = useState(null);
  const [newPwd, setNewPwd] = useState();
  const [newPwdErr, setNewPwdErr] = useState(null);
  const [verify, setVerify] = useState();
  const [verifyErr, setVerifyErr] = useState(null);

  useEffect(() => {
    setOldPwdErr(null);
    setNewPwdErr(null);
    setVerifyErr(null);
  }, [open])

  const handleSubmit = () => {
    if (!oldPwd) {
      setOldPwdErr('请输入旧密码')
    }
    if (!newPwd) {
      setNewPwdErr('请输入新密码')
    }
    if (!verify) {
      setVerifyErr('请验证新密码')
    }
    if (!oldPwd || !newPwd || !verify) {
      return;
    }
    if (newPwd !== verify) {
      setVerifyErr("两次输入的密码不一致");
      setNewPwdErr("两次输入的密码不一致");
      return;
    }
    if (oldPwd === newPwd) {
      setOldPwdErr("新旧密码是一样的")
      setNewPwdErr("新旧密码是一样的")
      return;
    }

    submit({ oldPwd, newPwd });
    onClose();
  }

  return (
    <Dialog open={open}>
      <Box sx={{ width: 500, margin: 2 }}>
        <Typography align='center' sx={{ mt: 1, mb: 2, fontSize: 20 }}>
          修改密码
        </Typography>
        <Divider />
        <Grid container justifyContent="center">
          <Grid container sx={{ width: 400, margin: 2 }}>
            <InputElement label='旧密码' error={oldPwdErr} onClick={() => setOldPwdErr(null)} onChange={setOldPwd} />
            <InputElement label='新密码' error={newPwdErr} onClick={() => setNewPwdErr(null)} onChange={setNewPwd} />
            <InputElement label='再次输入' error={verifyErr} onClick={() => setVerifyErr(null)} onChange={setVerify} />
          </Grid>
        </Grid>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} autoFocus>取消</Button>
          <Button onClick={handleSubmit} autoFocus>确认</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}