import PropTypes from 'prop-types';
import {
  Button
} from "@mui/material";
import { ReportProblem, Done, East, Loop } from '@mui/icons-material';

function RoundedButton({ icon, label, color }) {
  const sx = {
    borderRadius: '10px', // 圆角半径
    padding: '10px 10px', // 按钮内边距
    display: 'flex',
    alignItems: 'center', // 垂直居中
    height: '20px',
  };
  return (
    <Button
      sx={sx}
      startIcon={icon}
      variant='outlined'
      color={color}
    >
      {label}
    </Button>
  );
}

export function Status({ value }) {
  switch (value) {
    case 'SUCCESS':
      return <RoundedButton icon={<Done />} color='success' label='成功' />
    case 'RECALL':
    case 'FAILED':
      return <RoundedButton icon={<ReportProblem />} color='error' label='失败' />
    case 'ASSIGN':
      return <RoundedButton icon={<Loop />} color='warning' label='处理' />
    default:
      return <RoundedButton icon={<East />} color='info' label='等待' />
  }
}
Status.propTypes = {
  value: PropTypes.string,
};