import { serverURL, loginPath } from '../config';

// report
export async function reportCommission(data = {}) {
  return get('/report/commission', data);
}

export async function reportCommissionSummary(data = {}) {
  return get('/report/commission/summary', data);
}

export async function reportReconciliation(data = {}) {
  return get('/report/reconciliation', data);
}

export async function reportMerchant(data = {}) {
  return get('/report/merchant', data);
}

export async function reportMerchantSummary(data = {}) {
  return get('/report/merchant/summary', data);
}

export async function reportInferiorTree() {
  return get('/report/inferiorTree');
}

// secure
export async function gauthVerify(code) {
  return get(`/secure/gauth/${code}`);
}

export async function gauthConfirm(code) {
  return post('/secure/gauth', { code });
}

export async function gauthReset(code) {
  return deleteRequest('/secure/gauth', { code });
}

export async function resetPassword(oldPassword, newPassword) {
  return put('/secure/password', { oldPassword, newPassword });
}

export async function logList(data = {}) {
  return get('/secure/logs', data);
}

// account
export async function balance(data = {}) {
  return get('/account/availableBalance', data);
}

export async function rateList(data = {}) {
  return get('/account/rate', data);
}

export async function walletList(data = {}) {
  return get('/account/sub', data);
}

export async function statementList(data = {}) {
  return get('/account/history', data);
}

// withdraw
export async function withdrawList(data = {}) {
  return get('/withdraw', data);
}

export async function withdrawAdd(data = {}) {
  return post('/withdraw', data);
}

export async function withdrawExport(timeStart, timeEnd) {
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'GET';
  form.action = `${serverURL}/withdraw/export`;
  form.target = '_blank';
  const timeStartEle = document.createElement('input');
  timeStartEle.setAttribute('name', 'timeStart');
  timeStartEle.setAttribute('type', 'hidden');
  timeStartEle.setAttribute('value', timeStart);
  form.appendChild(timeStartEle);
  const timeEndEle = document.createElement('input');
  timeEndEle.setAttribute('name', 'timeEnd');
  timeEndEle.setAttribute('type', 'hidden');
  timeEndEle.setAttribute('value', timeEnd);
  form.appendChild(timeEndEle);
  form.submit();
  try {
    document.body.removeChild(form);
  } catch {
    console.log('form 不存在!');
  }
}

export async function heartbeat() {
  return get('/heartbeat');
}

export async function me() {
  return get('/me');
}

export async function post(path, data = {}) {
  return request(`${serverURL}${path}`, 'post', toForm(data));
}

export async function put(path, data = {}) {
  return request(`${serverURL}${path}`, 'put', toForm(data));
}

export async function deleteRequest(path, data = {}) {
  return request(`${serverURL}${path}`, 'delete', toForm(data));
}

export async function get(path, data = {}) {
  const queryString = toForm(data);
  return request(`${serverURL}${path}?${new URLSearchParams(queryString).toString()}`, 'get');
}

function toForm(data) {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => body.append(key, value))
  return body;
}

async function request(url, method, body) {
  return fetch(url, {
    method,
    credentials: 'include',
    body
  })
    .then((response) => {
      if (response.headers.get('Content-Type').indexOf('image') !== -1) {
        return response;
      }
      switch (response.status) {
        case 401:
          console.error(`请重新登录 ${window.location.href}`);
          if (window.location.href.indexOf(loginPath) === -1) {
            window.location.href = loginPath;
          }
          break;
        case 403:
          console.error("没有权限");
          break;
        default:
      }
      const data = response.json();
      if (data.retcode && data.retcode !== 0) {
        if (data.retdesc) {
          console.error(data.retdesc);
        }
      }
      return data;
    })
    .catch((err) => {
      console.error(err.message);
    });;
}
