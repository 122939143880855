import { Alert, Backdrop, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

// 数据仓库
const StoreContext = createContext();
function StoreProvider({ children }) {
  const [store, setStore] = useState({});

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
}

// 弹框提示
const SnackbarContext = createContext({
  text: '',
  type: '',
  setSnackbar: () => { },
});
const SnackbarProvider = ({ children }) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');

  const setSnackbar = (text, type) => {
    setText(text);
    setType(type);
  };

  return (
    <SnackbarContext.Provider
      value={{
        text,
        type,
        setSnackbar,
      }}
    >
      <SnackbarPopup />
      {children}
    </SnackbarContext.Provider>
  );
};

function SnackbarPopup() {
  const { text, type = 'success', setSnackbar } = useContext(SnackbarContext);
  if (text && type) {
    return (
      <Snackbar
        open={Boolean(text && type)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={type === 'error' ? null : 3000}
        onClose={() => { setSnackbar('', '') }}
      >
        <Alert severity={type}>{text}</Alert>
      </Snackbar>
    );
  }
}

// 背景
const BackdropContext = createContext();
function BackdropProvider({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <BackdropContext.Provider value={{ open, setOpen }}>
      <BackdropPopup />
      {children}
    </BackdropContext.Provider>
  );
}
function BackdropPopup() {
  const { open } = useBackdrop();
  if (open) {
    return (
      <Backdrop
        open={Boolean(open)}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
    );
  }
}

export const useStore = () => useContext(StoreContext);
export const useSnackbar = () => useContext(SnackbarContext);
export const useBackdrop = () => useContext(BackdropContext);
export function GlobalProvider({ children }) {
  return (
    <StoreProvider>
      <SnackbarProvider>
        <BackdropProvider>
          {children}
        </BackdropProvider>
      </SnackbarProvider>
    </StoreProvider>
  );
}